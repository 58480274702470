// Reset default styles 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥

html {
	scroll-behavior: smooth;
}

svg {
	vertical-align: initial;
}

.ant-card {
	box-shadow: @card-shadow;
}

.ant-select.ant-select,
.ant-select-item.ant-select-item {
	color: #444444;
	transition: all 0.15s ease;
	&.ant-select-item-option-disabled {
		color: inherit;
	}
}

li.ant-timeline-item.ant-timeline-item-last {
	padding-bottom: 0;
}

.ant-collapse {
	&.ant-collapse-borderless > .ant-collapse-item {
		border-bottom: none;

		&:last-child .ant-collapse-header {
			font-weight: normal;
		}

		&.border-none {
			border: none;
		}

		&.shadow {
			box-shadow: @box-shadow-base;
		}
	}
}

.ant-layout {
	min-height: 100vh;
}

.ant-layout-sider {
	transition: all 0.1s ease;
	&.ant-layout-sider-dark {
		background: @layout-trigger-background;
	}
	.ant-layout-sider-children {
		padding-left: 1.5em;
		padding-top: 3em;
	}
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
	background: @layout-trigger-background;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
	margin-bottom: 15px;
}

.ant-page-header {
	padding: 0 0 2em 0;
	.ant-page-header-heading-title {
		font-weight: 800;
	}
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	border-radius: 10px 0 0 10px;
}

.ant-statistic-title {
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

// Custom styles

.ant-upload-list {
	margin-bottom: 1em;
}

.ant-alert {
	.ant-alert-message {
		.ant-btn {
			height: 0;
		}
	}

	&.ant-alert-with-description {
		&.title-fw__normal {
			.ant-alert-message {
				font-weight: normal;
			}
		}
		&.title-mb1rem {
			.ant-alert-message {
				margin-bottom: 1rem;
			}
		}
		.ant-alert-message {
			font-weight: bold;
		}
	}
}

.ant-list {
	&.same-height {
		.ant-col {
			height: 100%;
			.ant-list-item {
				height: 100%;
				.ant-card {
					height: 100%;
				}
			}
		}
	}

	&.header-border-none {
		.ant-list-header {
			border: none;
		}
	}

	.ant-card {
		position: relative;
		.delete {
			font-size: 20px;
			display: block;
			position: absolute;
			top: -7px;
			right: 5px;
			cursor: pointer;
		}
	}
}

.ant-form-item-explain,
.ant-form-item-extra {
	padding-top: 5px;
	font-size: 12px;
	color: @secondary;
}

.ant-card {
	.ant-card-head-title {
		.ant-typography {
			.text {
				font-size: 14px;
				font-weight: normal;
			}
		}
	}

	&.px0 {
		.ant-card-body {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&.body-p-3rem {
		.ant-card-body {
			padding: 3rem;
		}
	}

	&.title-border-none {
		.ant-card-head {
			border-bottom: none;
		}
	}

	&.selectable {
		transition: all 0.2s ease;
		cursor: pointer;

		&.selected {
			border: 1px solid @green-light;
			.anticon {
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}

		&:hover {
			background: #f9f9f9;
		}
	}
}

.ant-steps {
	// &.ant-steps-horizontal {
	// .ant-steps-item-icon {
	// 	display: block;
	// 	margin-bottom: 8px;
	// }
	// }

	.ant-steps-item-title {
		font-weight: 600;
	}

	.ant-steps-item-process {
		&.loading {
			.ant-steps-item-icon {
				background: none;
				border-color: @success-color;
				.anticon {
					color: @success-color;
				}
			}
		}

		.ant-steps-item-icon {
			background: @success-color;
			border-color: @success-color;
		}
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			border-color: @success-color;
			.ant-steps-finish-icon {
				color: @success-color;
			}
		}

		> .ant-steps-item-container {
			> .ant-steps-item-tail::after {
				background-color: @success-color;
			}
		}
	}

	&.ant-steps-small {
		.ant-steps-item-description {
			font-size: 11px;
		}
	}

	// &.ant-steps-vertical {
	// 	.ant-steps-item {
	// 		margin-bottom: 14px;
	// 	}
	// }
}

.ant-select-item-option-content {
	text-overflow: unset !important;
	white-space: pre-wrap !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

// Tabs

.ant-tabs {
	&.ant-tabs-top > {
		.ant-tabs-nav::before {
			border-bottom: 1px solid #dddddd;
		}
	}

	&.ant-tabs-card.ant-tabs-large {
		> .ant-tabs-nav {
			.ant-tabs-tab {
				min-width: 120px;
				justify-content: center;
			}
		}
	}
}

.state-tracker {
	.ant-card {
		.ant-card-head-title {
			font-weight: bold;
		}
		.ant-typography {
			.title {
				margin-bottom: 0;
				font-weight: bold;
				font-size: 0.8em;
				text-transform: uppercase;
				color: @secondary;
				line-height: 1.2;
			}

			&:not(.title) {
				font-size: 0.9em;
			}
		}
	}
}

@media print {
	.print-hide {
		display: none;
	}
}
