@media screen and (max-width: 414px) {
	.px1rem__mobile.px1rem__mobile {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.px0__mobile.px0__mobile {
		padding-left: 0;
		padding-right: 0;
	}

	.p0__mobile.p0__mobile {
		padding: 0;
	}
}
