@import "~antd/dist/antd.less";
@import "./custom.less";
@import "./responsive.less";

@green-light: #87d068;
@secondary: fade(black, 35%);
@gray-light: #f3f3f3;

// Margins

.my0.my0 {
	margin-top: 0;
	margin-bottom: 0;
}

.mb1rem.mb1rem {
	margin-bottom: 1rem;
}

.mb2rem.mb2rem {
	margin-bottom: 2rem;
}

.mb3rem.mb3rem {
	margin-bottom: 3rem;
}

.mb0.mb0.mb0 {
	margin-bottom: 0;
}

.mt1rem.mt1rem {
	margin-top: 1rem;
}

.my1rem.my1rem {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.mr1rem.mr1rem {
	margin-right: 1rem;
}

// Paddings

.p0.p0 {
	padding: 0;
}

.px0.px0 {
	padding-left: 0;
	padding-right: 0;
}

.px1rem.px1rem {
	padding-left: 1rem;
	padding-right: 1rem;
}

.px2rem.px2rem {
	padding-left: 2rem;
	padding-right: 2rem;
}

.pl0.pl0 {
	padding-left: 0;
}

.pl1rem.pl1rem {
	padding-left: 1rem;
}

.p1rem.p1rem {
	padding: 1rem;
}

.p2rem.p2rem {
	padding: 2rem;
}

.pt2rem.pt2rem {
	padding-top: 2rem;
}

.pt3rem.pt3rem {
	padding-top: 3rem;
}

.pt4rem.pt4rem {
	padding-top: 4rem;
}

.py2rem.py2rem {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.py3rem.py3rem {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

// Colors

.primary.primary {
	color: @primary-color;
}

.secondary.secondary {
	color: @secondary;
}

.text-light {
	color: @text-color-dark;
}

.text-light-trans {
	color: fade(@text-color-dark, 50%);
}

.black.black {
	color: black;
}

.text-gray-light.text-gray-light {
	color: @gray-light;
}

// Displays

.d-none.d-none {
	display: none;
}

.d-block.d-block {
	display: block;
}

// Width and height

.h-100.h-100 {
	height: 100%;
}

.w-100.w-100 {
	width: 100%;
}

.w-75.w-75 {
	width: 75%;
}

// Aligns

.ta-right.ta-right {
	text-align: right;
}

.ta-center.ta-center {
	text-align: center;
}

.ta-left.ta-left {
	text-align: left;
}

// Radius

.br-10.br-10 {
	border-radius: 10px;
}

// Fonts!

.fs-small.fs-small {
	font-size: 0.9em;
}

.fs-smaller.fs-smaller {
	font-size: 0.8em;
}

.fs-even-smaller.fs-even-smaller {
	font-size: 0.7em;
}

.fw-bold.fw-bold {
	font-weight: bold;
}

.fw-bolder.fw-bolder {
	font-weight: bolder;
}

// Bg colors

.bg-primary.bg-primary {
	background: @primary-color;
}

.bg--light_grey.bg--light_grey {
	background-color: #f9f9f9;
}

.bg--warning.bg--warning {
	background-color: ivory;
}

.bg--success.bg--success {
	background-color: fade(@success-color, 10%);
}

.bg--error.bg--error {
	background-color: fade(@error-color, 10%);
}

.bg-transparent.bg-transparent {
	background-color: transparent;
}

// Borders

.border-none.border-none {
	border: none;
}

// Box shadows

.shadow-none.shadow-none {
	box-shadow: none;
}

.shadow-1.shadow-1 {
	box-shadow: 1px 4px 9px rgba(0, 0, 0, 0.3);
}

// Text cases

.uppercase.uppercase {
	text-transform: uppercase;
}

.lowercase.lowercase {
	text-transform: lowercase;
}

.cap.cap {
	text-transform: capitalize;
}

// Others

.valign-sub.valign-sub {
	vertical-align: sub;
}

.no-click.no-click {
	pointer-events: none;
}

.cursor-not-allowed.cursor-not-allowed {
	cursor: not-allowed;
}

.ls-1.ls-1 {
	letter-spacing: 1px;
}

.ls-2.ls-2 {
	letter-spacing: 2px;
}

.ls-3.ls-3 {
	letter-spacing: 3px;
}

// Line heights

.line-height-normal.line-height-normal {
	line-height: normal;
}

.line-height-1.line-height-1 {
	line-height: 1.2;
}

.line-height-2.line-height-2 {
	line-height: 1.4;
}

.line-height-3.line-height-3 {
	line-height: 1.6;
}

.line-height-4.line-height-4 {
	line-height: 1.8;
}

.line-height-5.line-height-5 {
	line-height: 2;
}

// Positions

.absolute.absolute {
	position: absolute;
}

.relative.relative {
	position: relative;
}
