@tailwind base;
@tailwind components;
@tailwind utilities;

button[type="submit"] {
	@apply font-semibold py-4 inline-flex items-center;
}

/* button:not([role="menuitem"]):not([role="dropdown"]):not([type="submit"]) {
	@apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 transition;
} */

button:disabled {
	@apply text-gray-200 bg-gray-50 cursor-not-allowed;
}

@layer base {
	svg {
		vertical-align: initial;
	}

	a {
		@apply text-orange-500 hover:underline;
	}

	@media print {
		.recharts-responsive-container,
		.recharts-wrapper,
		.recharts-surface,
		.recharts-legend-wrapper {
			width: 100% !important;
		}
		.recharts-legend-item > svg {
			width: auto !important;
			display: inline-block !important;
			right: inherit !important; /* align to left */
		}
	}
}

@layer components {
	nav > a.active {
		@apply text-orange-500;
	}

	.ant-badge .ant-badge-status-text {
		color: inherit;
	}
}

@layer utilities {
	.uppercase-title {
		@apply tracking-wider text-xs text-gray-400 uppercase;
	}

	.backdrop-blur {
		backdrop-filter: blur(10px);
	}
	@variants responsive {
		.text-shadow {
			text-shadow: 2px 2px 4px black;
		}
		.text-shadow-md {
			text-shadow: 4px 4px 8px black;
		}
		.text-shadow-lg {
			text-shadow: 15px 15px 30px black;
		}
		.text-shadow-none {
			text-shadow: none;
		}
	}
}
